<template>
  <div>
    <!-- <v-btn
      v-if="$admin.can('md-makes.create')"
      color="primary"
      dark
      @click="openModal"
    >
      {{ $t("add_new") }}
    </v-btn> -->
    <!-- v-if="isVisibleDialog" -->
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('md-makes.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="openModal"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="mdMake.name"
                    dense
                    :label="$t('name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="mdMake.is_active"
                    dense
                    :items="statusLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('status_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="mdMake.make_type"
                    dense
                    :items="makeTypeList"
                    item-text="name"
                    item-value="id"
                    :label="$t('make_type_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  v-if="mdMake.make_type == 'tenant'"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="mdMake.tenant_id"
                    dense
                    :disabled="tenantLoading"
                    :loading="tenantLoading"
                    :items="tenantsList.filter((r) => r.product == 'oem')"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_tenant_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(mdMake)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      mdMake: {
        make_type: "default",
      },
      makeTypeList: [
        { id: "default", name: "Default" },
        { id: "tenant", name: "Tenant" },
      ],
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantsList",
    }),
  },

  watch: {
    // add any required watchers here
    "mdMake.make_type": {
      handler: function () {
        this.mdMake.tenant_id = null;
      },
    },
  },

  // async mounted() {
  // if (this.authUser.isAdmin) {
  // await this.$store.dispatch("meta/getClients", "tenant");
  // }
  // },

  methods: {
    openModal() {
      this.isVisibleDialog = !this.isVisibleDialog;
    },
    closeForm() {
      // this.$refs.form.reset();
      this.mdMake = {
        make_type: "default",
      };
      this.isVisibleDialog = false;
    },
    async saveItem(mdMake) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        let params = { mdMake, filter: { ...this.filterOptions } };
        await this.$store
          .dispatch("mdMakes/add", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
